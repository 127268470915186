<template>
    <v-dialog
    v-model="dialog"
    max-width="70%"
    >
        <v-card dark>
            <v-img
                lazy-src="https://picsum.photos/id/11/10/6"
                :height="height"
                contain
                :src="selectedImage"
            >
                <v-btn
                    class="mt-2 mr-2"
                    style="position: absolute; top: 0; right: 0;"
                    elevation="2"
                    size="12"
                    fab
                    @click="hide"
                >
                    <v-icon> mdi-close</v-icon>
                </v-btn>
            </v-img>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        selectedImage: null,
        height: 0
    }),
    methods: {
        show(image){
            this.height = window.innerHeight - 100;
            this.selectedImage = image;
            this.dialog = true;
            this.isValid = false;
        },
        hide(){
            this.dialog = false;
            this.errorMessage = '';
        }
    }
};
</script>