import { checkAllowAddress } from './text-mapping';

const nameRules = [
    v => !!v || 'โปรดกรอกชื่อลูกค้า'
];

const mobileRules = [
    v => !!v || 'โปรดกรอกเบอร์โทรศัพท์',
    v => (10 == v.length || 9 == v.length) || 'ตัวอย่างรูปแบบเบอร์โทร ex. 0394992346',
    v => {
        const pattern = /^\d+$/;
        const result = pattern.test(v);
        return result || 'ตัวอย่างรูปแบบเบอร์โทร ex. 0394992346';
    }
];

const emailRules = [
    v => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง';
    },
    v => !!v || 'โปรดกรอกอีเมล'
];

const addressRules = [
    v => !!v || 'โปรดกรอกที่อยู่',
    v => v && 250 > v.length || 'ที่อยู่ต้องไม่เกิน 250 ตัวอักษร'
];

const numberRules = [
    v => !!v || 'โปรดกรอกข้อมูล',
    v => /^\d+$/.test(v) || 'เฉพาะตัวเลขเท่านั้น'
];

const numberWithCommasRules = [
    v => !!v || 'โปรดกรอกข้อมูล',
    v => /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/.test(v) || 'เฉพาะตัวเลขเท่านั้น'
];

const numberAndDecimalRules = [
    v => !!v || 'โปรดกรอกข้อมูล',
    v => /^\d*\.?\d{0,5}$/.test(v) || 'เฉพาะตัวเลขเท่านั้น'
];

const mapRules = [
    v => !!v || 'โปรดใส่ที่อยู่',
    v => ' ' != v || 'โปรดใส่ที่อยู่'
];

const mapRulesV2 = (v, c) => {
    return [
        v => !!v || 'โปรดใส่ที่อยู่',
        v => ' ' != v || 'โปรดใส่ที่อยู่',
        v => checkAllowAddress(v, c) || 'อยู่นอกพื้นที่ให้บริการ'
    ];
};

const requiredRules = [
    v => !!v || 'โปรดกรอกข้อมูล'
];

const taxRules = [
    v => !!v || 'โปรดกรอกข้อมูลบัตรประชาชนหรือเลขที่ผู้เสียภาษี',
    v => v && 13 == v.length || 'โปรดกรอกข้อมูลบัตรประชาชนหรือเลขที่ผู้เสียภาษี 13 หลัก',
    v => {
        const pattern = /^\d+$/;
        const result = pattern.test(v);
        return result || 'เฉพาะหมายเลขเท่านั้น';
    }
];

export {
    nameRules,
    mobileRules,
    emailRules,
    addressRules,
    numberRules,
    mapRules,
    mapRulesV2,
    requiredRules,
    numberWithCommasRules,
    numberAndDecimalRules,
    taxRules
};