<template>
    <v-dialog autofocus v-model="dialog" persistent max-width="480" overlay-opacity="0.8">
        <v-card dark class="pa-5" align="center">
            <v-card-text>
                <p class="text-body-1 font-weight-bold mb-0 mt-3 yellow--text text--darken-3"> เพิ่มสาขา </p>
                <v-form ref="addBranchForm" v-model="isValid">
                    <v-text-field autofocus label="ชื่อสาขา" color="yellow darken-3" v-model="name" maxlength=30
                        :rules="[v => v != null && v.length > 2 || 'โปรดใส่ชื่อสาขา']"></v-text-field>
                    <v-text-field autofocus label="เบอร์โทร" color="yellow darken-3" v-model="mobile" maxlength=20>
                    </v-text-field>
                    <v-textarea class="mt-1" label="ที่อยู่" dark color="yellow darken-3" outlined no-resize rows="3"
                        v-model="address" :rules="[v => v != null && v.length > 5 || 'โปรดใส่รายละเอียดที่อยู่']">
                    </v-textarea>
                </v-form>

            </v-card-text>
            <v-card-actions align="center">
                <v-btn color="yellow darken-3" outlined @click="dialog = false" class="mx-auto px-8">
                    ยกเลิก
                </v-btn>
                <v-btn color="yellow darken-3" @click="confirm" class="mx-auto px-8">
                    <span class="black--text">
                        ยืนยัน
                    </span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        name: '',
        mobile: '',
        address: '',
        isValid: false
    }),
    props: {
    },
    methods: {
        show(){
            this.name = '';
            this.address = '';
            this.mobile = '';
            this.dialog = true;
            setTimeout(() => {
                this.$refs.addBranchForm.resetValidation();
            }, 500);
        },
        confirm(){
            this.$refs.addBranchForm.validate();
            if(this.isValid){
                this.dialog = false;
                this.$emit('confirm', { name: this.name, mobile: this.mobile, address: this.address });
            }
            
        }
    },
    mounted(){
        // this.$refs.addBranchForm.resetValidation();
    }
};
</script>
