<template>
    <div @click="handleClick" class="image-upload my-2" :style="{ width: width + 'px', height: height + 'px' }">
        <v-icon v-if="image == null" size="40" class="icon" color="black">
            mdi-plus
        </v-icon>
        
        <v-btn color="white" icon v-if="image != null" @click.prevent="remove" class="icon-close" :style="{ left: (width - 35) + 'px'}">
            <v-icon size="30">
                mdi-close
            </v-icon>
        </v-btn>
        <v-img style="border-radius: 12px;" 
            @click="show"
            v-if="image != null" 
            width="100%" 
            height="100%" 
            lazy-src="https://picsum.photos/id/11/10/6" 
            :src="imageShow">
        </v-img>
        
        <input 
            ref="uploader" 
            class="d-none" 
            type="file" 
            @change="onFileChanged"
        >
        <dialog-image  ref="dialogImage" />
    </div>
</template>

<script>
import DialogImage from '../components/DialogImage.vue';

export default {
    props: {
        width: {
            type: Number,
            default: 150
        },
        height: {
            type: Number,
            default: 150
        }
     
    },
    data: () => ({
        image: null,
        isDelete: false,
        imageShow: null
    }),
    methods: {
        handleClick(){
            if(null == this.image){
                this.$refs.uploader.click();
            }
        },
        dialogUpload(){
            this.$refs.uploader.click();
        },
        setImageFromUrl(imageUrl){
            this.image = true;
            this.imageShow = imageUrl;
        },
        show(){
            this.$refs.dialogImage.show(this.imageShow);
        },
        remove(){
            setTimeout(() => {
                this.image = null;
                this.isDelete = true;
                this.imageShow = null;
            }, 100);
        },
        onFileChanged(e){
            if(e != null){
                this.image = e.target.files[0];
                this.imageShow = window.URL.createObjectURL(this.image);
                this.isDelete = false;
            }else{
                this.image = null;
                this.isDelete = true;
                this.imageShow = null;
            }
            this.$emit('change', this.image);
        },
        getImage(){
            return this.image;
        }
    },
    components: {
        DialogImage
    }
};
</script>

<style scoped>
    .image-upload{
        border: 1px solid #FFFFFF;
        border-radius: 12px;
        background-color: #FFFFFF;
        position: relative;
    }
    .image-upload:hover{
        cursor: pointer;
    }
    .icon{
        display: flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        height:100%;
        align-items: center;
        justify-content: center;
    }
    .icon-close{
        position: absolute;
        z-index: 90;
    }
</style>