<template>
    <v-container>
        <p class="text-h6 white--text font-weight-bold">จัดการ Content</p>
        <v-tabs v-model="tab" color="yellow darken-3" dark background-color="#191B20">
            <v-tab href="#tab-1">หน้าหลัก</v-tab>
            <v-tab href="#tab-2">ข่าวสาร</v-tab>
            <v-tab href="#tab-3">เกี่ยวกับเรา</v-tab>
            <v-tab href="#tab-4">ติดต่อเรา</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background-color: #191B20 !important;">
            <v-tab-item :value="'tab-1'">
                <v-container>
                    <div class="d-flex justify-space-between">
                        <label class="text-body-2 font-weight-bold d-block white--text">Section: 1</label>
                    </div>
                    <v-card class="mt-5 pt-5 px-10" flat dark>
                        <v-row>
                            <v-col>
                                <label class="text-body-2 font-weight-bold d-block white--text">link youtube</label>
                                <v-text-field v-model="customerIndex.section1.youtube"
                                    placeholder="ใส่ link youtube ตัวอย่าง https://www.youtube.com/watch?v=U9x7gloCmYM"
                                    class="mr-3 mt-n2" color="yellow darken-2">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <label class="text-body-2 font-weight-bold d-block white--text">รูปภาพ</label>
                                <div class="d-flex">
                                    <image-upload class="mr-4" @change="getSection1Image1" ref="section1Image1" />
                                    <image-upload @change="getSection1Image2" ref="section1Image2" />
                                </div>
                            </v-col>
                            <v-col cols="8">
                                <label class="text-body-2 font-weight-bold d-block white--text">ข้อมูล</label>
                                <label class="mt-2 text-caption grey--text d-block">หัวเรื่อง</label>
                                <v-text-field v-model="customerIndex.section1.topic" class="mr-3 mt-n2"
                                    color="yellow darken-2"></v-text-field>
                                <label class="mt-2 text-caption grey--text d-block">เนื้อหา 1</label>
                                <v-text-field v-model="customerIndex.section1.description1" class="mr-3 mt-n2"
                                    color="yellow darken-2"></v-text-field>
                                <label class="mt-2 text-caption grey--text d-block">เนื้อหา 2</label>
                                <v-text-field v-model="customerIndex.section1.description2" class="mr-3 mt-n2"
                                    color="yellow darken-2"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                    <div class="d-flex justify-space-between mt-10">
                        <label class="text-body-2 font-weight-bold d-block white--text">Section: 2</label>
                    </div>
                    <v-card class="mt-5 pt-5 px-10" flat dark>
                        <v-row>
                            <v-col cols="12">
                                <label class="text-body-2 font-weight-bold d-block white--text">ข้อมูล</label>
                                <label class="mt-2 text-caption grey--text d-block">หัวเรื่อง</label>
                                <v-text-field v-model="customerIndex.section2.topic" class="mr-3 mt-n2"
                                    color="yellow darken-2"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row>
                            <v-col cols="8">
                                <label class="text-body-2 font-weight-bold d-block white--text">Move Sameday</label>
                                <label class="mt-2 text-caption grey--text d-block">คำอธิบาย</label>
                                <v-textarea dark color="yellow darken-3" outlined no-resize rows="2"
                                    v-model="customerIndex.section2.channel1.content"></v-textarea>
                                <label class="text-caption grey--text d-block">รายละเอียดทั้งหมด</label>
                                <v-textarea dark color="yellow darken-3" outlined no-resize rows="2"
                                    v-model="customerIndex.section2.channel1.fullContent"></v-textarea>
                            </v-col>
                            <v-col cols="4">
                                <image-upload class="mt-10 mx-auto" @change="getSection1Image2"
                                    ref="section2Channel1Image" />
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="mt-3">
                            <v-col cols="8">
                                <label class="text-body-2 font-weight-bold d-block white--text">Move Express</label>
                                <label class="mt-2 text-caption grey--text d-block">คำอธิบาย</label>
                                <v-textarea dark color="yellow darken-3" outlined no-resize rows="2"
                                    v-model="customerIndex.section2.channel2.content"></v-textarea>
                                <label class="text-caption grey--text d-block">รายละเอียดทั้งหมด</label>
                                <v-textarea dark color="yellow darken-3" outlined no-resize rows="2"
                                    v-model="customerIndex.section2.channel2.fullContent"></v-textarea>
                            </v-col>
                            <v-col cols="4">
                                <image-upload class="mt-10 mx-auto" @change="getSection1Image2"
                                    ref="section2Channel2Image" />
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="mt-3">
                            <v-col cols="8">
                                <label class="text-body-2 font-weight-bold d-block white--text">Move Transit</label>
                                <label class="mt-2 text-caption grey--text d-block">คำอธิบาย</label>
                                <v-textarea dark color="yellow darken-3" outlined no-resize rows="2"
                                    v-model="customerIndex.section2.channel3.content"></v-textarea>
                                <label class="text-caption grey--text d-block">รายละเอียดทั้งหมด</label>
                                <v-textarea dark color="yellow darken-3" outlined no-resize rows="2"
                                    v-model="customerIndex.section2.channel3.fullContent"></v-textarea>
                            </v-col>
                            <v-col cols="4">
                                <image-upload class="mt-10 mx-auto" @change="getSection1Image2"
                                    ref="section2Channel3Image" />
                            </v-col>
                        </v-row>
                    </v-card>
                    <div class="text-right mb-15">
                        <v-btn class="mt-10 mr-10" color="yellow darken-3" outlined @click="$refs.confirm.show()">
                            ยกเลิกรายการ
                        </v-btn>
                        <v-btn class="mt-10" color="yellow darken-3" @click="saveCustomerIndex">
                            บันทึกรายการ
                        </v-btn>
                    </div>
                </v-container>
            </v-tab-item>
            <v-tab-item :value="'tab-2'">
                <v-container class="text-right">
                    <v-btn @click="$refs.dialogContentForm.show()" class="mt-n2" outlined color="yellow darken-3" small>
                        <v-icon>
                            mdi-pencil-outline
                        </v-icon>
                        เพิ่มข่าวสาร
                    </v-btn>
                    <v-divider class="mt-5" dark />
                    <v-card class="mt-5 py-5 px-10" flat dark>
                        <div v-for="c of contents" :key="c.id" class="d-block mb-5">
                            <div class="d-flex">
                                <div>
                                    <v-img class="" lazy-src="https://picsum.photos/id/11/10/6" height="150" width="200"
                                        :src="c.image" @click="$refs.dialogImage.show(c.image)"></v-img>
                                </div>
                                <div class="ml-5 text-left">
                                    <label class="d-block font-weight-bold">
                                        {{ c.topic }}
                                    </label>
                                    <div class="grey--text" style="max-width: 450px; height: 120px; overflow: auto;">
                                        {{ c.content }}
                                    </div>
                                </div>
                                <div class="ml-auto">
                                    <v-btn icon :color="c.isHighlight ? 'yellow darken-3' : 'grey'"
                                        @click="toggleHighlight(c)" class="mx-3">
                                        <v-icon>mdi-star</v-icon>
                                    </v-btn>
                                    <v-btn v-if="!c.isPublish" outlined color="yellow darken-3"
                                        @click="$refs.confirmPublish.show(c.id)">เผยแพร่</v-btn>
                                    <v-btn v-if="c.isPublish" outlined color="red lighten-1"
                                        @click="$refs.confirmUnPublish.show(c.id)">ปิดการเผยแพร่</v-btn>
                                    <v-btn class="ml-2" outlined color="yellow darken-3"
                                        @click="$refs.dialogContentForm.show(c)">ดูข้อมูล</v-btn>
                                </div>
                            </div>
                            <v-divider class="my-3" dark />
                        </div>

                    </v-card>
                </v-container>
            </v-tab-item>
            <v-tab-item :value="'tab-3'">
                <v-container>
                    <v-card class="mt-5 py-5 px-10" flat dark>
                        <v-row>
                            <v-col>
                                <label class="text-body-2 font-weight-bold d-block white--text">ข้อมูลบริษัท</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="8">
                                <v-textarea class="mt-4" label="คำอธิบายบริษัท" dark color="yellow darken-3" outlined
                                    no-resize rows="4" v-model="customerAboutUs.group1.text"></v-textarea>
                            </v-col>
                            <v-col cols="4">
                                <image-upload class="mx-auto" @change="getSection1Image2"
                                    ref="customerAboutUsGroup1Image" />
                            </v-col>
                        </v-row>
                        <v-divider class="my-10" />
                        <v-row>
                            <v-col>
                                <label class="text-body-2 font-weight-bold d-block white--text">วิสัยทัศน์ของเรา</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="8">
                                <v-textarea class="mt-4" label="รายละเอียดทั้งหมด" dark color="yellow darken-3" outlined
                                    no-resize rows="4" v-model="customerAboutUs.group2.text"></v-textarea>
                            </v-col>
                            <v-col cols="4">
                                <image-upload class="mx-auto" @change="getSection1Image2"
                                    ref="customerAboutUsGroup2Image" />
                            </v-col>
                        </v-row>
                        <v-divider class="my-10" />
                        <v-row>
                            <v-col>
                                <label class="text-body-2 font-weight-bold d-block white--text">ภารกิจทั้งหมด</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="8">
                                <v-textarea class="mt-4" label="รายละเอียดทั้งหมด" dark color="yellow darken-3" outlined
                                    no-resize rows="4" v-model="customerAboutUs.group3.text"></v-textarea>
                            </v-col>
                            <v-col cols="4">
                                <image-upload class="mx-auto" @change="getSection1Image2"
                                    ref="customerAboutUsGroup3Image" />
                            </v-col>
                        </v-row>
                        <v-divider class="my-10" />
                    </v-card>
                    <!-- Button zone -->
                    <div class="text-right mb-15">
                        <v-btn class="mt-10 mr-10" color="yellow darken-3" outlined @click="$refs.confirm.show()">
                            ยกเลิกรายการ
                        </v-btn>
                        <v-btn class="mt-10" color="yellow darken-3" @click="saveCustomerAboutUs">
                            บันทึกรายการ
                        </v-btn>
                    </div>
                    <!-- End Button zone -->
                </v-container>
            </v-tab-item>
            <v-tab-item :value="'tab-4'">
                <v-container class="mb-15">
                    <v-card class="mt-5 py-5 px-10" flat dark>
                        <v-form v-model="isContactUsValid" ref="formContactUs">
                            <v-row>
                                <v-col cols="12">
                                    <label
                                        class="text-body-2 font-weight-bold d-block white--text">ข้อมูลการติดต่อ</label>
                                </v-col>
                                <v-col cols="12">
                                    <label class="mt-2 text-caption grey--text d-block">ชื่อบริษัท</label>
                                    <v-text-field :rules="requiredRules" v-model="customerContactUs.companyName"
                                        class="mr-3 mt-n2" color="yellow darken-2"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <label class="mt-2 text-caption grey--text d-block">เบอร์โทร</label>
                                    <v-text-field :rules="requiredRules" v-model="customerContactUs.mobile"
                                        class="mr-3 mt-n2" color="yellow darken-2"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <label class="mt-2 text-caption grey--text d-block">อีเมล</label>
                                    <v-text-field :rules="emailRules" v-model="customerContactUs.email"
                                        class="mr-3 mt-n2" color="yellow darken-2"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <label class="mt-2 text-caption grey--text d-block">ที่อยู่</label>
                                    <v-textarea class="mt-1" dark color="yellow darken-3" outlined no-resize rows="2"
                                        v-model="customerContactUs.address" :rules="addressRules"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                        <div class="d-block text-right mb-15">
                            <v-btn class="mt-10 mr-10" color="yellow darken-3" outlined @click="$refs.confirm.show()">
                                ยกเลิกรายการ
                            </v-btn>
                            <v-btn class="mt-10" color="yellow darken-3" @click="saveCustomerContactUs">
                                <span class="black--text">
                                    บันทึกรายการ
                                </span>
                            </v-btn>
                        </div>
                    </v-card>
                    <div class="d-flex justify-space-between mx-10 mt-10">
                        <label class="text-body-2 font-weight-bold d-block white--text">ข้อมูลสาขา</label>
                        <v-btn class="mt-n2" outlined color="yellow darken-3" small
                            @click="$refs.dialogAddBranch.show()">
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            เพิ่มสาขา
                        </v-btn>
                    </div>
                    <v-card v-for="(branch, index) of customerContactUs.branches" :key="branch.id"
                        class="mt-5 py-5 px-10" flat dark>
                        <v-row>
                            <v-col cols="2">
                                <label class="text-body-2 font-weight-bold d-block white--text">สาขา{{ branch.name
                                    }}</label>
                            </v-col>
                            <v-col cols="6">
                                <!-- <v-textarea
                                        disabled
                                        class="mt-1"
                                        dark
                                        color="yellow darken-3"
                                        outlined
                                        no-resize
                                        rows="2"
                                        :value="branch.address"
                                    ></v-textarea> -->
                                <label class="grey--text text--lighten-1 text-body-1">
                                    {{ branch.address }}
                                </label>
                                <label class="grey--text d-block">
                                    เบอร์โทร {{ branch.mobile ? branch.mobile : '-' }}
                                </label>
                            </v-col>
                            <v-col cols="3">
                                <v-btn small class="mt-1" color="yellow darken-3" outlined
                                    @click="$refs.dialogEditBranch.show(branch.id, branch.name, branch.mobile, branch.address)">
                                    <v-icon small>
                                        mdi-circle-edit-outline
                                    </v-icon>
                                    แก้ไข
                                </v-btn>
                                <v-btn small class="mt-1 ml-2" color="red lighten-1" outlined
                                    @click="showDialogConfirmDeleteBranch(index)">
                                    <v-icon small>
                                        mdi-delete
                                    </v-icon>
                                    ลบ
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-container>
            </v-tab-item>
        </v-tabs-items>
        <dialog-confirm :title="'ยืนยันยกเลิกรายการ'"
            :description="'โปรดตรวจสอบทุกครั้งว่าได้บันทึกข้อมูลแล้วหรือยัง เพื่อป้องกันไม่ไห้ข้อมูลหายไปหลังจากกดออก'"
            @confirm="cancel" ref="confirm" />
        <dialog-confirm :title="'ยืนยันเปิดการเผยแพร่ข่าวสาร'"
            :description="'โปรดตรวจสอบทุกครั้งว่าข้อมูลถูกต้องแล้วหรือยัง ก่อนทำการเผยแพร่ข่าวสาร'"
            @confirm="publishContent" ref="confirmPublish" />
        <dialog-confirm :title="'ยืนยันปิดการเผยแพร่ข่าวสาร'"
            :description="'ข่าวสารที่กำลังเผยแพร่อยู่จะหายไปหากทำการกดปิดการเผยแพร่ข่าวสาร'" @confirm="unPublishContent"
            ref="confirmUnPublish" />
        <dialog-confirm :title="confirmDeleteBranchText"
            :description="'โปรดตรวจสอบทุกครั้งว่าเลือกสาขาที่ต้องการลบถูกหรือไม่'" @confirm="deleteBranch"
            ref="confirmDeleteBranch" />
        <dialog-add-branch @confirm="addBranch" ref="dialogAddBranch" />
        <dialog-edit-branch @confirm="editBranch" ref="dialogEditBranch" />
        <dialog-content-form @created="createdContent" @updated="updatedContent" ref="dialogContentForm" />
        <alert-error :text="errorMessage" ref="alertError" />
        <alert-success :text="successMessage" ref="alertSuccess"></alert-success>
        <dialog-image ref="dialogImage" />
    </v-container>
</template>

<script>
import ImageUpload from '../../components/ImageUpload.vue';
import DialogConfirm from '../../components/DialogConfirm.vue';
import DialogAddBranch from '../../components/DialogAddBranch.vue';
import DialogEditBranch from '../../components/DialogEditBranch.vue';
import DialogContentForm from '../../components/DialogContentForm.vue';
import AlertError from '../../components/AlertError.vue';
import AlertSuccess from '../../components/AlertSuccess.vue';
import axios from 'axios';
import DialogImage from '../../components/DialogImage.vue';
import { mobileRules, emailRules, addressRules, requiredRules } from '../../utils/validation-rule';

export default {
    data: () => ({
        mobileRules,
        emailRules,
        addressRules,
        requiredRules,
        tab: 1,
        errorMessage: '',
        successMessage: '',
        section1Image1: null,
        section1Image2: null,
        value: '',
        confirmDeleteBranchText: '',
        customerIndex: {
            section1: {},
            section2: {
                channel1: {},
                channel2: {},
                channel3: {}
            }
        },
        customerAboutUs: {
            group1: {},
            group2: {},
            group3: {}
        },
        customerContactUs: {
            branches: []
        },
        contents: [],
        isContactUsValid: false
    }),
    methods: {
        getSection1Image1(e){
            this.section1Image1 = e;
        },
        getSection1Image2(e){
            this.section1Image2 = e;
        },
        cancel(){
            this.$router.push({ name: 'Home' });
        },
        showDialogConfirmDeleteBranch(index){
            this.confirmDeleteBranchText = `ยืนยันการลบ "${this.customerContactUs.branches[index].name}"`;
            this.$refs.confirmDeleteBranch.show(index);
        },
        async addBranch(branch){
            let loader = this.$loading.show();
            try{
                const data = {
                    name: branch.name,
                    mobile: branch.mobile,
                    address: branch.address
                };
                const response = await axios.post(process.env.VUE_APP_BASE_URL + '/contents/branches', data);
                this.successMessage = 'เพิ่มสาขาเรียบร้อยแล้ว';
                this.$refs.alertSuccess.show();
                this.customerContactUs.branches.push({ id: response.data.id, name: response.data.name, address: response.data.address });
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        async editBranch(branch){
            let loader = this.$loading.show();
            try{
                const data = {
                    name: branch.name,
                    mobile: branch.mobile,
                    address: branch.address
                };
                await axios.patch(process.env.VUE_APP_BASE_URL + '/contents/branches/' + branch.id, data);
                this.successMessage = 'เพิ่มสาขาเรียบร้อยแล้ว';
                this.$refs.alertSuccess.show();
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                await this.getAllData();
                loader.hide();
            }
        },
        async deleteBranch(index){
            let loader = this.$loading.show();
            try{
                const branch = this.customerContactUs.branches[index];
                await axios.delete(process.env.VUE_APP_BASE_URL + `/contents/branches/${branch.id}`);
                this.successMessage = 'ลบสาขาเรียบร้อยแล้ว';
                this.$refs.alertSuccess.show();
                this.customerContactUs.branches.splice(index, 1);
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        async createdContent(){
            this.successMessage = 'เพิ่มข่าวเรียบร้อยแล้ว';
            this.$refs.alertSuccess.show();
            this.refreshContent();
        },
        async updatedContent(){
            this.successMessage = 'อัพเดรตข่าวเรียบร้อยแล้ว';
            this.$refs.alertSuccess.show();
            this.refreshContent();
        },
        async refreshContent(){
            let loader = this.$loading.show();
            try{
                // Customer Announcement (content)
                const response = await axios.get(process.env.VUE_APP_BASE_URL + '/contents');
                this.contents = response.data;
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        async publishContent(id){
            let loader = this.$loading.show();
            try{
                const index = this.contents.findIndex(c => c.id == id);
                if(index < 0){
                    throw Error('not found content');
                }
                await axios.post(process.env.VUE_APP_BASE_URL + `/contents/publish/${id}`);
                this.successMessage = 'เผยแพร่ข่าวเรียบร้อยแล้ว';
                this.$refs.alertSuccess.show();
                this.contents[index].isPublish = true;
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        async unPublishContent(id){
            let loader = this.$loading.show();
            try{
                const index = this.contents.findIndex(c => c.id == id);
                if(index < 0){
                    throw Error('not found content');
                }
                await axios.post(process.env.VUE_APP_BASE_URL + `/contents/unpublish/${id}`);
                this.successMessage = 'หยุดเผยแพร่ข่าวเรียบร้อยแล้ว';
                this.$refs.alertSuccess.show();
                this.contents[index].isPublish = false;
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        async getAllData(){
            let loader = this.$loading.show();
            try{
                // Get All Data
                // Customer Index
                const response = await axios.get(process.env.VUE_APP_BASE_URL + '/contents/customer-index');
                this.customerIndex = response.data;
                if(this.customerIndex.section1.image1){
                    this.$refs.section1Image1.setImageFromUrl(this.customerIndex.section1.image1);
                }
                if(this.customerIndex.section1.image2){
                    this.$refs.section1Image2.setImageFromUrl(this.customerIndex.section1.image2);
                }
                if(this.customerIndex.section2.channel1.image){
                    this.$refs.section2Channel1Image.setImageFromUrl(this.customerIndex.section2.channel1.image);
                }
                if(this.customerIndex.section2.channel2.image){
                    this.$refs.section2Channel2Image.setImageFromUrl(this.customerIndex.section2.channel2.image);
                }
                if(this.customerIndex.section2.channel3.image){
                    this.$refs.section2Channel3Image.setImageFromUrl(this.customerIndex.section2.channel3.image);
                }

                // Customer About Us
                const response2 = await axios.get(process.env.VUE_APP_BASE_URL + '/contents/about-us');
                this.customerAboutUs = response2.data;

                // Customer Contact Us
                const response3 = await axios.get(process.env.VUE_APP_BASE_URL + '/contents/contact-us');
                this.customerContactUs = response3.data;

                // Customer Announcement (content)
                const response4 = await axios.get(process.env.VUE_APP_BASE_URL + '/contents');
                this.contents = response4.data;

            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        async saveCustomerIndex(){
            let loader = this.$loading.show();
            try{
                const formData = new FormData();
                const section1Image1 = this.$refs.section1Image1.getImage();
                formData.append('section1_image1', section1Image1 instanceof File ? section1Image1 : null);
                const section1Image2 = this.$refs.section1Image2.getImage();
                formData.append('section1_image2', section1Image2 instanceof File ? section1Image2 : null);
                formData.append('section1_topic', this.customerIndex.section1.topic);
                formData.append('section1_description1', this.customerIndex.section1.description1);
                formData.append('section1_description2', this.customerIndex.section1.description2);
                formData.append('section1_youtube', this.customerIndex.section1.youtube);
                formData.append('section2_topic', this.customerIndex.section2.topic);

                const section2Channel1Image = this.$refs.section2Channel1Image.getImage();
                formData.append('section2_channel1_image', section2Channel1Image instanceof File ? section2Channel1Image : null);
                formData.append('section2_channel1_content', this.customerIndex.section2.channel1.content);
                formData.append('section2_channel1_full', this.customerIndex.section2.channel1.fullContent);

                const section2Channel2Image = this.$refs.section2Channel2Image.getImage();
                formData.append('section2_channel2_image', section2Channel2Image instanceof File ? section2Channel2Image : null);
                formData.append('section2_channel2_content', this.customerIndex.section2.channel2.content);
                formData.append('section2_channel2_full', this.customerIndex.section2.channel2.fullContent);

                const section2Channel3Image = this.$refs.section2Channel3Image.getImage();
                formData.append('section2_channel3_image', section2Channel3Image instanceof File ? section2Channel3Image : null);
                formData.append('section2_channel3_content', this.customerIndex.section2.channel3.content);
                formData.append('section2_channel3_full', this.customerIndex.section2.channel3.fullContent);

                const response = await axios.post(process.env.VUE_APP_BASE_URL + '/contents/index', 
                    formData, 
                    { headers: { 'Content-Type': 'multipart/form-data' } });
                this.successMessage = 'บันทึกหน้าหลักเรียบร้อยแล้ว';
                this.$refs.alertSuccess.show();
                this.customerIndex = response.data;
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        async saveCustomerAboutUs(){
            let loader = this.$loading.show();
            try{
                const formData = new FormData();
                const customerAboutUsGroup1Image = this.$refs.customerAboutUsGroup1Image.getImage();
                formData.append('group1_image', customerAboutUsGroup1Image instanceof File ? customerAboutUsGroup1Image : null);
                formData.append('group1_text', this.customerAboutUs.group1.text);

                const customerAboutUsGroup2Image = this.$refs.customerAboutUsGroup2Image.getImage();
                formData.append('group2_image', customerAboutUsGroup2Image instanceof File ? customerAboutUsGroup2Image : null);
                formData.append('group2_text', this.customerAboutUs.group2.text);

                const customerAboutUsGroup3Image = this.$refs.customerAboutUsGroup3Image.getImage();
                formData.append('group3_image', customerAboutUsGroup3Image instanceof File ? customerAboutUsGroup3Image : null);
                formData.append('group3_text', this.customerAboutUs.group3.text);

                const response = await axios.post(process.env.VUE_APP_BASE_URL + '/contents/about-us', 
                    formData, 
                    { headers: { 'Content-Type': 'multipart/form-data' } });
                this.successMessage = 'บันทึก "เกี่ยวกับเรา" เรียบร้อยแล้ว';
                this.$refs.alertSuccess.show();
                this.customerAboutUs = response.data;
            }catch(error){
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            }finally{
                loader.hide();
            }
        },
        async saveCustomerContactUs(){
            this.$refs.formContactUs.validate();
            if(this.isContactUsValid){
                let loader = this.$loading.show();
                try{
                    const data = {
                        companyName: this.customerContactUs.companyName,
                        mobile: this.customerContactUs.mobile,
                        email: this.customerContactUs.email,
                        address: this.customerContactUs.address
                    };
                    const response = await axios.post(process.env.VUE_APP_BASE_URL + '/contents/contact-us', data);
                    this.successMessage = 'บันทึก "ติดต่อเรา" เรียบร้อยแล้ว';
                    this.$refs.alertSuccess.show();
                    this.customerContactUs = response.data;
                }catch(error){
                    this.errorMessage = error.response?.data || error.message;
                    this.$refs.alertError.show();
                }finally{
                    loader.hide();
                }
            }
        },
        async toggleHighlight (content) {
            let loader = this.$loading.show();
            try {
                await axios.post(process.env.VUE_APP_BASE_URL + '/contents/toggle-highlight/' + content.id);
                content.isHighlight = !content.isHighlight;
            } catch (error) {
                this.errorMessage = error.response?.data || error.message;
                this.$refs.alertError.show();
            } finally {
                loader.hide();
            }
        }
    },
    computed: {
    },
    async mounted(){
        await this.getAllData();
    },
    watch: {
        tab(){
            // Fix behavior error when get refs other default tab
           if('tab-3' == this.tab){
               setTimeout(() => {
                    if(this.customerAboutUs.group1.image){
                        this.$refs.customerAboutUsGroup1Image.setImageFromUrl(this.customerAboutUs.group1.image);
                    }
                    if(this.customerAboutUs.group2.image){
                        this.$refs.customerAboutUsGroup2Image.setImageFromUrl(this.customerAboutUs.group2.image);
                    }
                    if(this.customerAboutUs.group3.image){
                        this.$refs.customerAboutUsGroup3Image.setImageFromUrl(this.customerAboutUs.group3.image);
                    }
               }, 500);
           }
        }
    },
    components: {
        ImageUpload,
        DialogConfirm,
        DialogAddBranch,
        DialogContentForm,
        AlertError,
        AlertSuccess,
        DialogImage,
        DialogEditBranch
    }
};
</script>