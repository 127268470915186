<template>
    <v-dialog
    autofocus
    v-model="dialog"
    persistent
    max-width="90%"
    overlay-opacity="0.8"
    >
        <v-card dark class="pa-5" align="center">
            <v-card-text>
                <p class="text-body-1 font-weight-bold mb-0 mt-3 yellow--text text--darken-3"> ข้อมูลข่าวสาร </p>
                <v-form ref="addBranchForm" v-model="isValid">
                    <v-text-field 
                        autofocus
                        label="หัวข้อข่าวสาร"
                        color="yellow darken-3"
                        v-model="topic"
                        maxlength=60
                        counter="60"
                        :rules="[v => v != null && v.length > 2 || 'โปรดใส่ชื่อสาขา']"
                    ></v-text-field>
                    <v-divider class="my-3" dark></v-divider>
                    <v-row>
                        <v-col cols="7">
                            <v-textarea
                                class="mt-1"
                                label="รายละเอียดทั้งหมด"
                                dark
                                color="yellow darken-3"
                                outlined
                                no-resize
                                rows="10"
                                v-model="content"
                                :rules="[v => v != null && v.length > 5 || 'โปรดใส่รายละเอียดทั้งหมด']"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="5">
                            <image-upload :width="280" :height="280" @change="changeImage" ref="theContentImageJa" />
                        </v-col>
                    </v-row>
                </v-form>
                
            </v-card-text>
            <v-card-actions>
                <div style="width: 100%; text-align: right;" v-if="id == null">
                    <v-btn
                        color="yellow darken-3"
                        outlined
                        @click="closeWithoutChange"
                        class="px-8"
                    >
                        ยกเลิกรายการ
                    </v-btn>
                    <v-btn
                        color="yellow darken-3"
                        outlined
                        @click="save"
                        class="ml-3 px-8"
                    >
                        บันทึกแบบร่าง
                    </v-btn>
                    <v-btn
                        color="yellow darken-3"
                        @click="save(true)"
                        class="ml-3 px-8"
                    >
                        <span class="black--text">
                            เผยแพร่
                        </span>
                    </v-btn>
                </div>
                <div style="width: 100%; text-align: right;" v-else>
                    <v-btn
                        color="yellow darken-3"
                        outlined
                        @click="closeWithoutChange"
                        class="px-8"
                    >
                        ยกเลิกรายการ
                    </v-btn>
                    <v-btn
                        color="yellow darken-3"
                        @click="save"
                        class="ml-3 px-8"
                    >
                        <span class="black--text">
                            บันทึก
                        </span>
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>

        <alert-error :text="errorMessage" ref="alertError" />
    </v-dialog>
</template>

<script>
import ImageUpload from './ImageUpload.vue';
import axios from 'axios';
import AlertError from '../components/AlertError.vue'; 

export default {
    data: () => ({
        dialog: false,
        topic: '',
        content: '',
        isValid: false,
        id: null,
        errorMessage: ''
    }),
    props: {
    },
    methods: {
        show(content){
            this.dialog = true;
            if(content){
                this.id = content.id;
                this.topic = content.topic;
                this.content = content.content;
                if(content.image){
                    setTimeout(() => {
                        this.$refs.theContentImageJa.setImageFromUrl(content.image);
                    }, 500);
                }
            }else{
                this.topic = '';
                this.content = '';
                setTimeout(() => {
                    this.$refs.addBranchForm.resetValidation();
                    this.$refs.theContentImageJa.remove();
                }, 500);
            }
        },
        async save(isPublish){
            this.$refs.addBranchForm.validate();
            if(this.isValid){
                let loader = this.$loading.show();
                if(this.id){ // update
                    const formData = new FormData();
                    formData.append('topic', this.topic);
                    formData.append('content', this.content);
                    const image = this.$refs.theContentImageJa.getImage();
                    formData.append('image', image instanceof File ? image : null);
                    try{
                        await axios.patch(process.env.VUE_APP_BASE_URL + `/contents/${this.id}`, 
                            formData, 
                            { headers: { 'Content-Type': 'multipart/form-data' } });
                        this.$emit('updated');
                    }catch(error){
                        this.errorMessage = error.response?.data || error.message;
                        this.$refs.alertError.show();
                    }finally{
                        loader.hide();
                    }
                }else { // create
                    const formData = new FormData();
                    formData.append('topic', this.topic);
                    formData.append('content', this.content);
                    formData.append('isPublish', isPublish || false);
                    const image = this.$refs.theContentImageJa.getImage();
                    formData.append('image', image instanceof File ? image : null);
                    try{
                        await axios.post(process.env.VUE_APP_BASE_URL + '/contents', 
                            formData, 
                            { headers: { 'Content-Type': 'multipart/form-data' } });
                        this.$emit('created');
                    }catch(error){
                        this.errorMessage = error.response?.data || error.message;
                        this.$refs.alertError.show();
                    }finally{
                        loader.hide();
                    }
                }
                this.dialog = false;
            }
        },
        changeImage(){
            console.log('image changed!');
        },
        closeWithoutChange(){
            this.$refs.theContentImageJa.remove();
            this.dialog = false;
            this.id = null;
        }
    },
    mounted(){
        // this.$refs.addBranchForm.resetValidation();
    },
    components: {
        ImageUpload,
        AlertError
    }
};
</script>
